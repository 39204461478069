export default {
  header: {
    logout: 'Logout',
    menu: 'Menu',
    profile: 'Profile',
    hello: 'Welcome, {{firstName}} {{lastName}}'
  },
  labels: {
    error: 'Error'
  },
  buttons: {
    create: 'Create',
    update: 'Update',
    cancel: 'Cancel',
    close: 'Close',
    no: 'No',
    yes: 'Yes',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    export: 'Export'
  },
  table: {
    actions: {
      delete: '',
      edit: ''
    }
  },
  select: {
    all: 'All',
    buttons: {
      selectAll: 'Select All',
      deselectAll: 'Deselect All',
    }
  },
  errors: {
    generic: 'Something went wrong',
    email: 'Please provide valid email',
    required: 'This field is required',
    alphanumeric: 'Password should contain at least 1 number, at least 1 letter and at least 1 symbol <>?\\.[]!@#$%^&*()_+|~-=',
    maxlength: 'Max length is {{requiredLength}}',
    minlength: 'Min length is {{requiredLength}}',
    phone: 'Enter valid phone number'
  },
  sidebar: {
    menus: {
      dashboard: 'Dashboard',
      brandRatings: 'Brand Ratings',
      planElements: 'Plan Elements',
      netPromoterScores: 'Net Promoter Scores',
      typesOfAppointments: 'Types of Appointments',
      areasOfImprovement: 'Areas of Improvement',
      healthCareServicesUsage: 'Health Care Services',
      satisfactionDegree: 'Degree of Satisfaction',
      equityAndWellness: 'Equity and Wellness',
      covid19Issues: 'COVID-19 Issues',
      sourcesOfInformation: 'Sources of Information',
      ethnicity: 'Ethnicity',
      surveyQuestions: 'Survey Questions',
      languageCoverage: 'Language Coverage',
      overview: 'Overview',
      users: 'Users',
      tableKeyValues: 'Table of Key Values',
      export: 'Export'
    }
  },
  pages: {
    notFound: {
      generic: 'Not found',
      specific: 'Object does not exist or deleted {{url}}'
    },
    login: {
      firstStep: {
        title: 'Sign in',
        subTitle: 'to access your account',
      },
      secondStep: {
        title: 'Sign in',
        subTitle: 'A message with a verification code has been sent to your email.<br/>Enter the code to continue.',
      },
      signInBtn: 'SIGN IN',
      backBtn: 'Return to Login Screen',
      form: {
        email: 'Your Email',
        password: 'Your Password',
        rememberMe: 'Remember me'
      },
      errors: {
        wrongPassOrEmail: 'Incorrect email or password',
        wrongCode: 'Incorrect verification code'
      }
    },
    home: {
      header: 'Dashboard',
      title: 'Member Satisfaction',
      subTitle: 'What Area of Member Satisfaction would you like to explore?',
      card: {
        body: {
          active: 'Active',
          archive: 'Archive',
          brandRatings: 'Brand Ratings',
          planElements: 'Plan Elements',
          netPromoterScores: 'Net Promoter Scores',
          typesOfAppointments: 'Types of Appointments',
          areasOfImprovement: 'Areas of Improvement',
          covid19Issues: 'COVID-19 Issues',
          sourcesOfInformation: 'Sources of Information',
          ethnicity: 'Ethnicity',
          overview: 'Overview',
          surveyQuestions: 'Survey Questions',
          healthCareServicesUsage: 'Usage of Health Care Services',
          satisfactionDegree: 'Degree of Satisfaction',
          equityAndWellness: 'Equity and Wellness',
          languageCoverage: 'Language Coverage',
          tableKeyValues: 'Table of Key Values'
        }
      },
    },
    brandRatings: {
      header: 'Brand Ratings'
    },
    planElements: {
      header: 'Plan Elements'
    },
    netPromoterScores: {
      header: 'Net Promoter Scores'
    },
    typesOfAppointments: {
      header: 'Types of Appointments'
    },
    areasOfImprovement: {
      header: 'Areas of Improvement'
    },
    healthCareServicesUsage: {
      header: 'Usage of Health Care Services'
    },
    satisfactionDegree: {
      header: 'Degree of Satisfaction'
    },
    equityAndWellness: {
      header: 'Equity and Wellness'
    },
    covid19Issues: {
      header: 'COVID-19 Issues',
      otherReason: {
        title: 'Other reasons for not getting vaccinated for Covid-19',
        description: 'Question asked of respondents who did not plan to get vaccinated for Covid-19.',
        noAnswers: 'No answers'
      }
    },
    sourcesOfInformation: {
      header: 'Sources of Information'
    },
    ethnicity: {
      header: 'Ethnicity',
      otherEthnicitiesTable: {
        title: 'Other Ethnicities',
        noAnswers: 'No answers'
      }
    },
    languageCoverage: {
      header: 'Language Coverage',
      body: {
        title: 'Note to the user about language-group coverage by wave',
        description: 'We did not seek Chinese, Russian, or Vietnamese respondents in Waves 1-3.' +
          '<br/>Therefore, the charts will show no values for these respondents in Waves 1-3.',
      }
    },
    overview: {
      header: 'Completes by CCO and Language',
      waves: {
        1: 'Wave 1 (May 31 to June 24, 2019)',
        2: 'Wave 2 (August 7-27, 2019)',
        3: 'Wave 3 (October 6-30, 2019)',
        4: 'Wave 4 (February 6-28, 2020)',
        5: 'Wave 5 (May 6-26, 2020)',
        6: 'Wave 6 (September 2-28, 2020)',
        7: 'Wave 7 (December 8, 2020 to January 5, 2021)',
        8: 'Wave 8 (March 10 to April 7, 2021)',
        9: 'Wave 9 (May 24 to June 29, 2021)',
        10: 'Wave 10 (August 16 to September 27, 2021)',
        11: 'Wave 11 (November 8 to December 28, 2021)',
        12: 'Wave 12 (February 14 – March 31, 2022)',
        13: 'Wave 13 (May 3 - June 21, 2022)',
        14: 'Wave 14 (July 29 - September 2, 2022)',
        15: 'Wave 15 (November 2 - December 9, 2022)',
        16: 'Wave 16 (March 6 - April 14, 2023)',
        17: 'Wave 17 (May 24 to July 7, 2023)',
        18: 'Wave 18 (August 24 to September 29, 2023)',
        19: 'Wave 19 (November 7 to December 15, 2023)',
        20: 'Wave 20 (March 14 – April 12, 2024)',
        21: 'Wave 21 (June 12 – July 3, 2024)',
        22: 'Wave 22 (August 28 – September 30, 2024)',
        23: 'Wave 23 (November 13 - December 11, 2024)'
      },
      waveNo: 'Wave {{waveNo}}',
      wavePeriod: {
        1: '(May 31 to June 24, 2019)',
        2: '(August 7-27, 2019)',
        3: '(October 6-30, 2019)',
        4: '(February 6-28, 2020)',
        5: '(May 6-26, 2020)',
        6: '(September 2-28, 2020)',
        7: '(December 8, 2020 to January 5, 2021)',
        8: '(March 10 to April 7, 2021)',
        9: '(May 24 to June 29, 2021)',
        10: '(August 16 to September 27, 2021)',
        11: '(November 8 to December 28, 2021)',
        12: '(February 14 to March 31, 2022)',
        13: '(May 3 to June 21, 2022)',
        14: '(July 29 to September 2, 2022)',
        15: '(November 2 to December 9, 2022)',
        16: '(March 6 to April 14, 2023)',
        17: '(May 24 to July 7, 2023)',
        18: '(August 24 to September 29, 2023)',
        19: '(November 7 to December 15, 2023)',
        20: '(March 14 – April 12, 2024)',
        21: '(June 12 – July 3, 2024)',
        22: '(August 28 – September 30, 2024)',
        23: '(November 13 - December 11, 2024)'
      }
    },
    surveyQuestions: {
      header: 'Survey Questions'
    },
    users: {
      fields: {
        firstName: 'First Name',
        lastName: 'Last name',
        email: 'Email',
        phone: 'Phone',
        password: 'Password',
        confirmPassword: 'Confirm password'
      },
      create: {
        title: 'Create user'
      },
      edit: {
        title: 'Edit user'
      },
      list: {
        header: 'Users',
        filter: {
          keyword: 'Keyword'
        },
        table: {
          header: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            phone: 'Phone',
          },
          delete: {
            title: 'Delete user',
            message: 'Are you sure you want to delete {{firstName}} {{lastName}} ?'
          }
        }
      },
      errors: {
        passwordNotMatch: 'Password does not match'
      }
    },
    profile: {
      title: 'Update profile',
      updatedMessage: 'Profile successfully updated!'
    },
    tableKeyValues: {
      header: 'Table of Key Values',
      tables: {
        meanRatings: 'Mean Ratings',
        topBoxRatings: 'Top 2 Box Ratings (percent)',
        netPromoterScores: 'Net Promoter Scores (percent)'
      }
    },
    export: {
      header: 'Export',
      tabs: {
        exportHistoryData: 'History data'
      }
    }
  },
  charts: {
    fields: {
      chartType: {
        legend: 'Chart Type:',
        options: {
          MEAN_RATINGS: 'Mean ratings',
          TOP_2_BOX: 'Top 2 box ratings',
          TOP_3_BOX: 'Top 3 box ratings',
          BOTTOM_2_BOX: 'Bottom 2 box ratings',
          PLAN_TO_GET_VACCINATED_FOR_COVID_19: 'Plan to get vaccinated for covid 19',
          WHY_DONT_YOU_PLAN_TO_GET_VACCINATED_FOR_COVID_19: 'Why don’t you plan to get vaccinated for Covid-19?',
          OTHER_COVID_19_REASON: 'Other reasons for not getting vaccinated for Covid-19',
          ETHNICITY: 'Ethnicity',
          OTHER_ETHNICITIES: 'Other Ethnicities',
          EQUITY_AND_WELLNESS: 'EQUITY_AND_WELLNESS',
          USAGE_OF_HEALTH_CARE_SERVICES: 'Use of Services',
          PRIMARY_CARE_WAIT_TIME: 'Primary Care Wait Time'
        },
        groups: {
          typeOfAppointment: {
            label: 'Type of Appointment',
            options: {
              TELEPHONE_APPOINTMENT: 'Telephone',
              VIDEO_APPOINTMENT: 'Video',
              IN_PERSON_APPOINTMENT: 'In-person',
            }
          },
          satisfactionWithAppointment: {
            label: 'Satisfaction with Appointment',
            options: {
              SOMEWHAT_OR_VERY_SATISF_TEL_APP: 'Telephone',
              SOMEWHAT_OR_VERY_SATISF_VID_APP: 'Video',
              SOMEWHAT_OR_VERY_SATISF_IN_PERS_APP: 'In-person',
            }
          },
          willingnessToConsiderAppointment: {
            label: 'Willingness to Consider Appointment',
            options: {
              CONSIDER_PHONE_APPOINTMENT: 'Telephone',
              CONSIDER_VIDEO_APPOINTMENT: 'Video',
              CONSIDER_IN_PERSON_APPOINTMENT: 'In-person',
            }
          },
          reasonsForNotConsideringAppointment: {
            label: 'Reasons for not Considering Appointment',
            options: {
              WILL_NOT_CONSIDER_PHONE_APPOINTMENT: 'Telephone',
              WILL_NOT_CONSIDER_VIDEO_APPOINTMENT: 'Video',
              WILL_NOT_CONSIDER_IN_PERSON_APPOINTMENT: 'In-person',
            }
          },
          equityAndWellness: {
            label: 'Equity and Wellness',
            options: {
              EQUITY_AND_WELLNESS_RESPECT: 'Respect',
              EQUITY_AND_WELLNESS_OVERALL: 'Overall wellness',
              EQUITY_AND_WELLNESS_HEALTH_GOALS: 'Health goals',
              EQUITY_AND_WELLNESS_CONTROL_OF_HEALTH: 'Control of health',
            }
          },
          equityAndWellnessInterpreters: {
            label: "Interpreters",
            options: {
              EQUITY_AND_WELLNESS_INTERPRETERS_DELAY: 'Delay',
              EQUITY_AND_WELLNESS_INTERPRETERS_WAIT_TIME: 'Wait time'
            }
          }
        },
      },
      groupBy: {
        legend: 'Group results by:',
        options: {
          NO_GROUP: 'No grouping',
          WAVE: 'Wave',
          BRAND: 'LOB',
          LANGUAGE: 'Language Group',
          ETHNICITY: 'Ethnicity'
        }
      },
      includeInAnalysis: {
        legend: 'Include in analysis:',
        brand: {
          label: 'LOB'
        },
        language: {
          label: 'Language Group'
        },
        wave: {
          label: 'Wave'
        },
        ethnicity: {
          label: 'Ethnicity'
        },
      }
    },
    MEAN_RATINGS: {
      dashNotation: {
        text: 'SIP benchmark (mean score of {{score}}) as established in the CareOregon Strategic Plan'
      },
      NO_GROUP: {
        title: 'Mean ratings',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        title: 'Mean ratings by LOB',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        title: 'Mean ratings by language group',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        title: 'Mean ratings by ethnicity',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: ''
      }
    },
    TOP_2_BOX: {
      dashNotation: {
        text: 'External CAHPS benchmark (top-2-box score of {{score}}%) as established by AHRQ'
      },
      NO_GROUP: {
        title: 'Top 2 box ratings',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        title: 'Top 2 box ratings by LOB',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        title: 'Top 2 box ratings by language group',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        title: 'Top 2 box ratings by ethnicity',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: ''
      }
    },
    NET_PROMOTER_SCORE: {
      dashNotation: {
        text: '2021 NPS Benchmark for Oregon, per NICE/Satmetrix.<br>' +
          '<small class="text-muted">Based on 20 Oregon residents rating 7 different plans in 2021, including Medicare.<br>Medicaid not included.</small>'
      },
      NO_GROUP: {
        title: 'Net Promoter Scores',
        description: '<b>Phraseology of the question</b>: "How likely is it that you would recommend [BRAND] to a friend or colleague?" (A 0-to-10 scale was used.)',
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        title: 'Net Promoter Scores by LOB',
        description: '<b>Phraseology of the question</b>: "How likely is it that you would recommend [BRAND] to a friend or colleague?" (A 0-to-10 scale was used.)',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        title: 'Net Promoter Scores by language group',
        description: '<b>Phraseology of the question</b>: "How likely is it that you would recommend [BRAND] to a friend or colleague?" (A 0-to-10 scale was used.)',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        title: 'Net Promoter Scores by ethnicity',
        description: '<b>Phraseology of the question</b>: "How likely is it that you would recommend [BRAND] to a friend or colleague?" (A 0-to-10 scale was used.)',
        asterisk: ''
      }
    },
    TELEPHONE_APPOINTMENT: {
      title: 'Percent of members who have had a telephone appointment',
      description: '<b>Phraseology of the question</b>: "Have you had any telephone appointments with a health care professional over the last six months?"',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        asterisk: ''
      }
    },
    VIDEO_APPOINTMENT: {
      title: 'Percent of members who have had a video appointment',
      description: '<b>Phraseology of the question</b>: "Have you had any video appointments with a health care professional over the last six months?"',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        asterisk: ''
      }
    },
    IN_PERSON_APPOINTMENT: {
      title: 'Percent of members who have had an in-person appointment',
      description: '<b>Phraseology of the question</b>: "Have you had any in-person appointments with a health care professional over the last six months?"',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        asterisk: ''
      }
    },
    SOMEWHAT_OR_VERY_SATISF_TEL_APP: {
      title: 'Percent somewhat or very satisfied with telephone appointment',
      description: '<b>Phraseology of the question</b>: "How satisfied were you with your telephone experience?"' +
        '<br/><br/>Question asked of respondents who had had a telephone appointment sometime in the past 6 months.',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        asterisk: ''
      }
    },
    SOMEWHAT_OR_VERY_SATISF_VID_APP: {
      title: 'Percent somewhat or very satisfied with video appointment',
      description: '<b>Phraseology of the question</b>: "How satisfied were you with your video experience?"' +
        '<br/><br/>Question asked of respondents who had had a video appointment sometime in the past 6 months.',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        asterisk: ''
      }
    },
    SOMEWHAT_OR_VERY_SATISF_IN_PERS_APP: {
      title: 'Percent somewhat or very satisfied with in-person appointment',
      description: '<b>Phraseology of the question</b>: "How satisfied were you with your in-person experience?"' +
        '<br/><br/>Question asked of respondents who had had an in-person appointment sometime in the past 6 months.',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        asterisk: ''
      }
    },
    CONSIDER_PHONE_APPOINTMENT: {
      title: 'Percent willing to consider a phone appointment',
      description: '<b>Phraseology of the question</b>: "Would you consider scheduling this kind of appointment with a health care professional?"' +
        '<br/><br/>Question asked of respondents who had not had a telephone appointment sometime in the past 6 months.',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        asterisk: ''
      }
    },
    CONSIDER_VIDEO_APPOINTMENT: {
      title: 'Percent willing to consider a video appointment',
      description: '<b>Phraseology of the question</b>: "Would you consider scheduling this kind of appointment with a health care professional?"' +
        '<br/><br/>Question asked of respondents who had not had a video appointment sometime in the past 6 months.',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        asterisk: ''
      }
    },
    CONSIDER_IN_PERSON_APPOINTMENT: {
      title: 'Percent willing to consider an in-person appointment',
      description: '<b>Phraseology of the question</b>: "Would you consider scheduling this kind of appointment with a health care professional?"' +
        '<br/><br/>Question asked of respondents who had not had an in-person appointment sometime in the past 6 months.',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        asterisk: ''
      }
    },
    WILL_NOT_CONSIDER_PHONE_APPOINTMENT: {
      title: 'Why wouldn\'t you consider a telephone appointment?',
      description: '<b>Phraseology of the question</b>: "Why wouldn\'t you consider such an appointment?"' +
        '<br/><br/>Question asked of respondents who were not willing to consider scheduling a telephone appointment.',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    WILL_NOT_CONSIDER_VIDEO_APPOINTMENT: {
      title: 'Why wouldn\'t you consider a video appointment?',
      description: '<b>Phraseology of the question</b>: "Why wouldn\'t you consider such an appointment?"' +
        '<br/><br/>Question asked of respondents who were not willing to consider scheduling a video appointment.',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    WILL_NOT_CONSIDER_IN_PERSON_APPOINTMENT: {
      title: 'Why wouldn\'t you consider a in-person appointment?',
      description: '<b>Phraseology of the question</b>: "Why wouldn\'t you consider such an appointment?"' +
        '<br/><br/>Question asked of respondents who were not willing to consider scheduling a in-person appointment.',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    PLAN_ELEMENTS: {
      title: 'What\'s included in your plan?',
      description: '<b>Phraseology of the question</b>: "So far as you know, which of these benefits are covered under your plan?"',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    AREAS_OF_IMPROVEMENT: {
      title: 'What would improve your experience as a member?',
      description: '<b>Phraseology of the question</b>: "How could [BRAND] improve your experience as a member?"' +
        '<br/><br/>Question asked of respondents who provided an overall rating of less than 8 on a 0-to-10 scale',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    USAGE_OF_HEALTH_CARE_SERVICES: {
      title: 'Usage of Health Care Services',
      BRAND: {
        title: 'Usage of Health Care Services by LOB',
      },
      LANGUAGE: {
        title: 'Usage of Health Care Services by language group',
      },
      WAVE: {
        title: 'Usage of Health Care Services by wave',
      },
      ETHNICITY: {
        title: 'Usage of Health Care Services by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: "Have you received that type of care in the past 6 months?"',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    PRIMARY_CARE_WAIT_TIME: {
      title: 'Primary Care Wait Time',
      BRAND: {
        title: 'Primary Care Wait Time by LOB',
      },
      LANGUAGE: {
        title: 'Primary Care Wait Time by language group',
      },
      WAVE: {
        title: 'Primary Care Wait Time by wave',
      },
      ETHNICITY: {
        title: 'Primary Care Wait Time by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: "How many days did you need to wait for the appointment? [If you made more than one appointment, please focus on the most recent one.]"',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    DEGREE_OF_SATISFACTION: {
      title: 'Degree of Satisfaction',
      BRAND: {
        title: 'Degree of Satisfaction by LOB',
      },
      LANGUAGE: {
        title: 'Degree of Satisfaction by language group',
      },
      WAVE: {
        title: 'Degree of Satisfaction by wave',
      },
      ETHNICITY: {
        title: 'Degree of Satisfaction by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: "I’m going to ask you how satisfied you were with certain medical services you received from [INSERT REGIONAL BRAND NAME FROM FILE]. Please give me your answer on a 0-to-10 scale, where 10 means extremely satisfied and 0 means extremely dissatisfied."',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    EQUITY_AND_WELLNESS: {
      title: 'Equity and Wellness',
      BRAND: {
        title: 'Equity and Wellness by LOB',
      },
      LANGUAGE: {
        title: 'Equity and Wellness by language group',
      },
      WAVE: {
        title: 'Equity and Wellness',
      },
      ETHNICITY: {
        title: 'Equity and Wellness by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: "I’m going to ask you how satisfied you were with certain medical services you received from [INSERT REGIONAL BRAND NAME FROM FILE]. Please give me your answer on a 0-to-10 scale, where 10 means extremely satisfied and 0 means extremely dissatisfied."',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'

    },
    EQUITY_AND_WELLNESS_RESPECT: {
      title: 'Equity and Wellness: Respect',
      BRAND: {
        title: 'Respect by LOB',
      },
      LANGUAGE: {
        title: 'Respect by language group',
      },
      WAVE: {
        title: 'Respect by wave',
      },
      ETHNICITY: {
        title: 'Respect by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: "Did you feel respected by the healthcare or service providers you worked with, in the sense of your personal identity or culture?"'

    },
    EQUITY_AND_WELLNESS_OVERALL: {
      title: 'Equity and Wellness: Overall wellness',
      BRAND: {
        title: 'Overall wellness by LOB',
      },
      LANGUAGE: {
        title: 'Overall wellness by language group',
      },
      WAVE: {
        title: 'Overall wellness by wave',
      },
      ETHNICITY: {
        title: 'Overall wellness by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: Do you feel that your overall wellness (if necessary:“your health and happiness”) was increased as a result of the care or services you received from the peer support specialist?"'
    },
    EQUITY_AND_WELLNESS_HEALTH_GOALS: {
      title: 'Equity and Wellness: Health goals',
      BRAND: {
        title: 'Health goals by LOB',
      },
      LANGUAGE: {
        title: 'Health goals by language group',
      },
      WAVE: {
        title: 'Health goals by wave',
      },
      ETHNICITY: {
        title: 'Health goals by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: "Did the peer support specialist help you reach your health goals?"'
    },
    EQUITY_AND_WELLNESS_CONTROL_OF_HEALTH: {
      title: 'Equity and Wellness: Control of health',
      BRAND: {
        title: 'Control of health by LOB',
      },
      LANGUAGE: {
        title: 'Control of health by language group',
      },
      WAVE: {
        title: 'Control of health by wave',
      },
      ETHNICITY: {
        title: 'Control of health by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: "Did you feel more in control of your health as a result of working with this peer support specialist?"'

    },
    EQUITY_AND_WELLNESS_INTERPRETERS_DELAY: {
      title: 'Interpreters: Delay',
      BRAND: {
        title: 'Delay by LOB',
      },
      LANGUAGE: {
        title: 'Delay by language group',
      },
      WAVE: {
        title: 'Delay by wave',
      },
      ETHNICITY: {
        title: 'Delay by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: "In the last 6 months, have you had to wait to receive care because of a delay with an interpreter or finding an interpreter?"'
    },
    EQUITY_AND_WELLNESS_INTERPRETERS_WAIT_TIME: {
      title: 'Interpreters: Wait time',
      BRAND: {
        title: 'Wait time by LOB',
      },
      LANGUAGE: {
        title: 'Wait time by language group',
      },
      WAVE: {
        title: 'Wait time by wave',
      },
      ETHNICITY: {
        title: 'Wait time by ethnicity',
      },
      description: '<b>Phraseology of the question</b>: "How long did you wait?"'
    },
    PLAN_TO_GET_VACCINATED_FOR_COVID_19: {
      title: 'Plan to get vaccinated for covid 19',
      description: '<b>Phraseology of the question</b>: "Have you been fully vaccinated for Covid-19 (including a booster shot if appropriate), or do you plan to do so?"',
      NO_GROUP: {
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      }
    },
    WHY_DONT_YOU_PLAN_TO_GET_VACCINATED_FOR_COVID_19: {
      title: 'Why don\'t you plan to get vaccinated for Covid-19?',
      description: '<b>Phraseology of the question</b>: "Why don\'t you plan to get vaccinated for Covid-19?"' +
        '<br/><br/>Question asked of respondents who did not plan to get vaccinated for Covid-19.',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    SOURCES_OF_INFORMATION: {
      title: 'How do you know what benefits or services are available to you?<br>(Responses of 5% or more)',
      description: '<b>Phraseology of the question</b>: "How do you know what benefits or services are available to you from [BRAND]?"',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    ETHNICITY: {
      title: 'Ethnicity',
      description: '',
      asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
    },
    BOTTOM_2_BOX: {
      NO_GROUP: {
        title: 'Bottom 2 box ratings',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        title: 'Bottom 2 box ratings by LOB',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        title: 'Bottom 2 box ratings by language group',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        title: 'Bottom 2 box ratings by ethnicity',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: ''
      }
    },
    TOP_3_BOX: {
      NO_GROUP: {
        title: 'Top 3 box ratings',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: 'There are no statistically significant Interwave differences (referring to adjacent waves)'
      },
      BRAND: {
        title: 'Top 3 box ratings by LOB',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      LANGUAGE: {
        title: 'Top 3 box ratings by language group',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: '<sup>*</sup>Significantly different from Total at the 95% Confidence Level'
      },
      ETHNICITY: {
        title: 'Top 3 box ratings by ethnicity',
        description: '<b>Phraseology of the question</b>: "Using a scale from 0-10, with 10 as the best, how would you rate your experience being a [BRAND] member?"',
        asterisk: ''
      }
    }
  },
  questions: {
    // Areas of Improvement
    coverage: 'Coverage',
    communication_issues: 'Communication issues',
    information_medical_records: 'Information medical records',
    empathy_issues: 'Empathy issues',
    network: 'Network',
    wait_times: 'Wait times',
    website: 'Website',
    better_appointment_times: 'Better appointment times',

    // health care services usage
    q6_old: 'Primary Care',
    q5b: 'Primary Care',
    q7: 'Specialists',
    q8: 'Dental care',
    q9: 'Mental health care',
    q10: 'Substance use treatment services',
    q11: 'Pregnancy / Postpartum care',
    q12: 'Chiropractic / Acupuncture',
    q13: 'Support to quit smoking',
    q14: 'Doulas',
    q15: 'Peer support specialists',
    q16: 'Peer wellness specialists',
    q17: 'Personal health navigators',
    q18: 'Community health workers',
    q19: 'Interpretation services',

    // Sources of Information
    q2_1: 'Letter/mail',
    q2_2: 'Phone/call/calling',
    q2_3: 'Website/web/<br/>internet/online/<br/>portal',
    q2_4: 'Handbook/booklet/<br/>brochure/literature/<br/>flyer/pamphlet/<br/>newsletter',
    q2_5: 'Doctor/provide/<br/>caregiver/PCP/clinic/<br/>clinic staff/nurse',
    q2_6: 'Customer Service/<br/>Member Services',
    q2_7: 'Packet/package/<br/>benefits summary/catalog',
    q2_8: 'Word of mouth/family/<br/>friend/co-worker/neighbor',
    q2_9: 'Card/ID card',
    q2_10: 'Insurance provider',
    q2_11: 'Government/VA',
    q2_12: 'Social Services/<br/>social worker/case worker',
    q2_13: 'Representative/agent/<br/>advisor/counselor/coordinator',
    q2_14: 'Email',
    q2_15: 'Pharmacy',
    q2_16: 'Directory',
    q2_17: 'Don\'t know/no idea',
    q2_18: '[Refused]',

    // Plan Elements
    q3_1: 'Dental',
    q3_2: 'Mental health care',
    q3_3: 'Translation and interpretation services',
    q3_4: 'Transportation support',
    q3_5: 'Hearing services',
    q3_6: 'Medical equipment',
    q3_7: 'Care Coordination',
    q3_8: 'Substance use treatment services',
    q3_9: 'Health related services',
    q3_10: 'Pregnancy / Postpartum',
    q3_11: 'Support to quit smoking',
    q3_12: 'Chiropractic / Acupuncture',

    // Ethnicity
    q21_1: 'White',
    q21_2: 'American Indian or Alaska Native',
    q21_3: 'Asian',
    q21_4: 'Black or African-American',
    q21_5: 'Hispanic or Latino/Latina',
    q21_6: 'Middle Eastern/North African',
    q21_7: 'Native Hawaiian or Pacific Islander',
    q21_8: 'Other',

    // Degree of Satisfaction
    q21_1_1: 'Primary Care',
    q23_1: 'Specialists',
    q25_1: 'Dental care',
    q27_1: 'Mental health care',
    q29_1: 'Substance use treatment services',
    q31_1: 'Pregnancy / Postpartum care',
    q33_1: 'Doulas',
    q38_1: 'Peer support specialists',
    q43_1: 'Peer wellness specialists',
    q48_1: 'Personal health navigators',
    q53_1: 'Community health workers',
    q58_1: 'Interpretation services',

    // Equity and Wellness
    q22_1: 'Primary Care',
    q24: 'Specialists',
    q26: 'Dental care',
    q28: 'Mental health care',
    q30: 'Substance use treatment services',
    q32: 'Pregnancy / Postpartum care',
    q34: 'Doulas',
    q39: 'Peer support specialists',
    q44: 'Peer wellness specialists',
    q49: 'Personal health navigators',
    q54: 'Community health workers',
    q59: 'Interpretation services',
    q35: 'Doulas',
    q40: 'Peer support specialists',
    q45: 'Peer wellness specialists',
    q50: 'Personal health navigators',
    q55: 'Community health workers',
    q60_old: 'Interpretation services',
    q36: 'Doulas',
    q41: 'Peer support specialists',
    q46: 'Peer wellness specialists',
    q51: 'Personal health navigators',
    q56: 'Community health workers',
    q37: 'Doulas',
    q42: 'Peer support specialists',
    q47: 'Peer wellness specialists',
    q52: 'Personal health navigators',
    q57: 'Community health workers',
    q60_new: 'Yes',
    q61_old: 'Interpretation services',

    // types of EQUITY_AND_WELLNESS_INTERPRETERS_WAIT_TIME
    q61_new_ans_1: 'Less than 1 day',
    q61_new_ans_2: '1-3 days',
    q61_new_ans_3: '4-7 days',
    q61_new_ans_4: 'More than 7 days',
    q61_new_ans_5: 'Don\'t know/too hard to say',

    // types of Usage of Health Care Services  Primary Care Wait Time
    q5a_ans_1: 'Less than 1 day',
    q5a_ans_2: '1-3 days',
    q5a_ans_3: '4-7 days',
    q5a_ans_4: 'More than 7 days',
    q5a_ans_5: 'Don\'t know/too hard to say',

    // Types of Appointments -> WILL_NOT_CONSIDER_PHONE_APPOINTMENT
    q7_1_no_desire_to_do_so: 'No desire to do so',
    q7_2_dont_know_how_to_do_it: 'Don\'t know how to do it',
    q7_3_dont_have_the_equipment_for_this: 'Don\'t have the equipment for this',
    q7_4_i_have_privacy_concerns_about_this: 'I have privacy concerns about this',
    q7_5_some_other_reason_specify: 'Some other reason',

    // Types of Appointments -> WILL_NOT_CONSIDER_VIDEO_APPOINTMENT
    q11_1_no_desire_to_do_so: 'No desire to do so',
    q11_2_dont_know_how_to_do_it: 'Don’t know how to do it',
    q11_3_dont_have_the_equipment_for_this: 'Don’t have the equipment for this',
    q11_4_i_have_privacy_concerns_about_this: 'I have privacy concerns about this',
    q11_5_i_dont_have_internet_service: 'I don’t have Internet service',
    q11_6_i_dont_have_wifi: 'I don’t have Wi-Fi',
    q11_7_some_other_reason_specify: 'Some other reason',

    // Types of Appointments -> WILL_NOT_CONSIDER_IN_PERSON_APPOINTMENT
    q15_1_no_medical_need: 'No medical need',
    q15_2_have_been_putting_off_treatment: 'Have been putting off treatment',
    q15_3_concerned_about_coronavirus: 'Concerned about coronavirus',
    q15_4_meant_to_but_forgot: 'Meant to, but forgot',
    q15_5_had_a_condition_but_it_got_better_on_its_own: 'Had a condition, but it got better on its own',
    q15_6_could_not_get_transportation: 'Could not get transportation',
    q15_7_some_other_reason_specify: 'Some other reason',

    // Covid-19 Issues -> WHY_DONT_YOU_PLAN_TO_GET_VACCINATED_FOR_COVID_19
    q17_1: 'I don’t think I need it',
    q17_2: 'I’m concerned about the side effects of vaccines',
    q17_3: 'I’m concerned about vaccines in general',
    q17_4: 'I don’t think the vaccine works',
    q17_5: 'They’re too hard to get',
    q17_6: 'Some other reason',
  }
}
